/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'display-fill': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M6 12q0 1-.25 1.5H5a.5.5 0 000 1h6a.5.5 0 000-1h-.75Q10 13 10 12h4c2 0 2-2 2-2V4c0-2-2-2-2-2H2C0 2 0 4 0 4v6c0 2 2 2 2 2z"/>',
    },
});
